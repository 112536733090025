<template>
    <resource-list-item :to="linkUrl" target="_blank" nofollow centered divided>
        <template #image>
            <icon v-if="typeConfig.icon" :icon="typeConfig.icon" :color="typeConfig.color"/>
			<cdn-image v-else-if="typeConfig.image" :src="typeConfig.image.path" :height="24" :ratio="typeConfig.image.ratio"/>
        </template>

        <template #title>
			<div class="flex flex-column justify-center">
				<p v-if="displayName" class="text-sm leading-tight truncate" :class="[ !!followerText ? 'mb-3':'mb-0']" :style="{color: typeConfig.color}">{{ displayName }}</p>
				<p v-if="followerText" class="text-4xs text-subtext-color mb-0 leading-normal truncate">{{ followerText }}</p>
			</div>
		</template>

        <template #right>
            <icon :icon="icons.external_link" color="gray-400" small/>
        </template>
    </resource-list-item>
</template>

<script setup lang="ts">
import {externalLinkIcon} from "@spoferan/nuxt-spoferan/icons";
import {type SocialMediaType} from "@spoferan/nuxt-spoferan/types";

const props = defineProps<{
	type: SocialMediaType;
	username?: string;
	url: string;
}>();

const {reactiveData: remoteTypeData} = !['website', 'strava'].includes(props.type) ? await useApiFetch(`/v1/services/social/${props.type}`, {
	guest: true,
	ssr: false,
	params: {username: props.username}
}): {reactiveData: {value: undefined}};

const {config: socialMediaConfig} = useSocialMedia();
const typeConfig = socialMediaConfig[props.type];
const {t, n} = useI18n();

const icons = {
	external_link: externalLinkIcon
}

const followersCount = computed(() => {
	return remoteTypeData.value?.followers;
});

const displayName = computed(() => {
	return remoteTypeData.value?.username || props.username;
});

const linkUrl = computed(() => {
	return remoteTypeData.value?.username || props.url;
});

const followerText = computed(() => {
	if (followersCount.value) {
		const followersCountAmount = parseInt(followersCount.value);
		switch (props.type) {
			case 'spoferan':
			case 'facebook':
				return t('param_label.fans', {count: n(followersCountAmount)}, followersCountAmount);
			case 'youtube':
				return t('param_label.subscribers', {count: n(followersCountAmount)}, followersCountAmount);
			default:
				return t('param_label.follower', {count: n(followersCountAmount)}, followersCountAmount);
		}
	}

	return null;
});

</script>